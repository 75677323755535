<template>
  <dialog open class="web-cam-dialog">
    <div class="web-cam-title">
      <h1>{{ title }}</h1>
      <button class="s-close" type="button" @click="() => $emit('close')">✕</button>
    </div>
    <video ref="webCam" class="wev-cam-video" autoplay playsinline></video>
    <canvas ref="canvas" class="d-none"></canvas>
    <div class="web-cam-actions">
      <SharedButton class="s-btn s-btn-bg-primary s-btn-xs" @click="() => snap()">拍照</SharedButton>
      <SharedButton class="s-btn s-btn-bg-primary s-btn-xs" @click="() => flip()">反轉鏡頭</SharedButton>
    </div>
    <dialog v-if="showReminder" open class="reminder-dialog">
      <div class="reminder-dialog-content m-4">
        <h2>{{ reminder.title }}</h2>
        <p>{{ reminder.text }}</p>
        <div class="mx-auto">
          <SharedButton class="s-btn s-btn-bg-primary s-btn-xs mx-auto" variant="" @click="() => showReminder = false">
            確定
          </SharedButton>
        </div>
      </div>
    </dialog>
  </dialog>
</template>

<script>
import Webcam from "./webCam";
import SharedButton from "@/components/Page/Liff/Shared/Button";

export default {
  components: {
    SharedButton,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    reminder: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    showReminder: true,
    webCamInstance: null,
  }),
  mounted() {
    this.init();
    this.start();
  },
  beforeDestroy() {
    this.webCamInstance.stop();
  },
  methods: {
    init() {
      this.webCamInstance = new Webcam(this.$refs.webCam, 'environment', this.$refs.canvas);
    },
    start() {
      this.webCamInstance.start()
    },
    flip() {
      this.webCamInstance.flip();
      this.start();
    },
    snap() {
      const imageBase64 = this.webCamInstance.snap();
      this.$emit('snap', imageBase64);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.web-cam-dialog {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: gray;
}

.wev-cam-video {
  flex: 1;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.web-cam-title {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
}

.web-cam-actions {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: white;
}

.reminder-dialog {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: transparent;
}

.reminder-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
}
</style>
